<style scoped>
@media print {
  @page {
    /* size: A5 landscape; */
    size: letter portrait;
    /* size: 9.5in 11in; */
    /* size: 241mm ; */
    margin: 10mm 5mm 5mm 5mm;
    /* size: 216mm 140mm; */
  }

  .page-break {
    page-break-before: always;
    counter-increment: pageCounter;
  }

  .page-counter::after {
    content: "Page " counter(pageCounter);
  }

  .footer,
  #non-printable {
    display: none !important;
  }

  #printable {
    display: block;
  }

  body {
    font-size: 100%;
  }

  table,
  td,
  th {
    font-size: 100%;
  }
  
  .page {
    page-break-after: always;
    counter-increment: pageCounter;
  }

  .page-footer {
    width: 100%;
    background-color: transparent !important;
    display: block; /* Ensures it displays if the condition is met */
    margin-top: 185mm;
  }
}

/* .page {
  page-break-after: always;
} */

table {
  background-color: white !important;
  color: black !important;
}

table tr {
  font-family: "Courier";
  line-height: normal;
  font-size: 12px;
}

.text-bold {
  font-weight: bold;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

#page-counter {
  display: table-footer-group;
}

/* #page-counter:after { */
  /* counter-increment: page; */
  /* content: counter(page) " of " counter(page); */
  /* content: "Page " counter(pageCounter); */
/* } */

.border-bottom {
  border-bottom: 1px dashed;
}

.text-align-top {
  vertical-align: top;
}
</style>

<template>
  <div>
    <div class="page-break" :key="idx" v-for="(data, idx) in datas">
      <table style="width: 100%">
        <thead>
          <tr>
            <td>
              <table width="100%" style="text-align: center; font-size: 14px">
                <tr>
                  <td><strong>HANDOVER</strong></td>
                </tr>
                <tr>
                  <td>
                    <strong>{{ data.company_name }}</strong>
                  </td>
                </tr>
              </table>
              <br />
              <table width="100%">
                <tr>
                  <td>Cabang</td>
                  <td>:</td>
                  <td>{{ data.warehouse_code }} {{ data.warehouse_name }}</td>
                  <td>Print</td>
                  <td>:</td>
                  <td>{{ data.print_counter }}</td>
                </tr>
                <tr>
                  <td>Shipment Number</td>
                  <td>:</td>
                  <td>
                    <nobr>{{ data.shipment_number }}</nobr>
                  </td>
                  <td>Print date</td>
                  <td>:</td>
                  <td>{{ dateNow }}</td>
                </tr>
                <tr>
                  <td>Tanggal Shipment</td>
                  <td>:</td>
                  <td>
                    <nobr>{{ formatDate(data.shipment_date) }}</nobr>
                  </td>
                  <td>Print time</td>
                  <td>:</td>
                  <td>{{ timeNow }}</td>
                </tr>

                <tr>
                  <td>Driver</td>
                  <td>:</td>
                  <td>
                    <nobr>{{ data.driver_name }}</nobr>
                  </td>
                  <td>Page</td>
                  <td>:</td>
                  <td>
                    {{ data.page }} of {{ data.totalPages }}
                  </td>
                </tr>
                <tr>
                  <td>Vehicle Number</td>
                  <td>:</td>
                  <td>
                    <nobr>{{ data.vehicle_number }}</nobr>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div style="margin-top: 5px"></div>
              <table style="width: 100%">
                <thead>
                  <tr style="border-top: 2px dashed; border-bottom: 2px dashed">
                    <td class="text-left text-bold" style="width: 20%">
                      Customer
                    </td>
                    <!-- <td class="text-left text-bold">Delivery Order</td> -->
                    <td class="text-left text-bold" style="padding-left: 3px">
                      Item
                    </td>
                    <!-- <td class="text-left text-bold">SKU Name</td> -->
                    <td class="text-left text-bold" style="width: 15%">
                      BATCH
                    </td>
                    <td
                      class="text-left text-bold"
                      style="width: 10%; padding-left: 3px"
                    >
                      ED
                    </td>
                    <td
                      class="text-left text-bold"
                      style="width: 5%; padding-left: 3px"
                    >
                      QTY
                    </td>
                    <td
                      class="text-left text-bold"
                      style="width: 5%; padding-left: 3px"
                    >
                      UOM
                    </td>
                  </tr>
                </thead>
                <div style="margin-top: 5px"></div>
                <tbody>
                  <template v-for="(tr, indexTr) in data.lines">
                    <template v-for="(tr2, indexTr2) in tr.do_line">
                      <template v-for="(tr3, indexTr3) in tr2.item_lines">
                        <tr
                          :class="`outerTable-${indexTr}-${indexTr2}-${indexTr3} `"
                          v-bind:key="`outerTable-${indexTr}-${indexTr2}-${indexTr3}`"
                        >
                          <td
                            v-if="indexTr3 == tr2.item_lines.length-1 && indexTr2 == tr.do_line.length-1 && indexTr3 == 0 && indexTr2 == 0"
                            class="py-1 text-align-top border-bottom"
                          >
                          {{ tr.customer_code }} {{ tr.customer_name }}
                          </td>
                          <td
                            v-else-if="indexTr3 == 0 && indexTr2 == 0"
                            class="py-1 text-align-top"
                          >
                            {{ tr.customer_code }} {{ tr.customer_name }}
                          </td>
                          <td
                            v-else-if="indexTr3 == tr2.item_lines.length-1 && indexTr2 == tr.do_line.length-1"
                            class="py-1 text-align-top border-bottom"
                          >
                          </td>
                          <td
                            v-else
                            class="py-1 text-align-top"
                          >
                          </td>
                          <td
                            class="py-1 border-bottom"
                            
                          >
                            {{ tr3.sku_code }} {{ tr3.item_name }}
                          </td>
                          <!-- <td class="py-1 border-bottom">
                            {{ tr3.item_name }}
                          </td> -->
                          <td
                            class="py-1 border-bottom"
                            
                          >
                            {{ tr3.batch_external }}
                          </td>
                          <td
                            class="py-1 border-bottom"
                            
                          >
                            {{ formatDate(tr3.expired_date) }}
                          </td>
                          <td
                            class="py-1 border-bottom"
                            
                          >
                            {{ tr3.amount }}
                          </td>
                          <td
                            class="py-1 border-bottom"
                            
                          >
                            {{ tr3.unit }}
                          </td>
                        </tr>
                      </template>
                    </template>
                  </template>
                </tbody>
              </table>
              <br />
              <div
                class="page-footer"
                :style="{ marginTop: `${185 - data.totalItems * 6.43}mm` }"
                v-if="data.isSignature"
              >
                <table style="width: 100%">
                  <tr>
                    <th style="text-align: center">Diserahkan oleh</th>
                    <th style="text-align: center">Diterima oleh</th>
                    <th style="text-align: center">Diketahui oleh</th>
                  </tr>
                  <tr>
                    <th style="height: 50px"></th>
                    <th style="height: 50px"></th>
                    <th style="height: 50px"></th>
                  </tr>
                  <tr>
                    <th style="text-align: center">Checker</th>
                    <th style="text-align: center">Driver</th>
                    <th style="text-align: center">SPV Logistic</th>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";
export default {
  name: "PrintPdf",
  // props: {
  //     handover_id: Number
  // },
  data() {
    return {
      id: null,
      handoverIds: null,
      // table: [],
      sto_plan_code: null,
      picking_code: null,
      selectedSrcWarehouse: null,
      selectedDestWarehouse: null,
      reqDeliveryDate: null,
      eta: null,
      // logoSDN: logoSDN,
      showInfo: false,
      // data: {},
      datas: [],
      dateNow: "",
      timeNow: "",
    };
  },
  mounted() {
    // console.log("opener", window.opener.handoverIds)
    var now = moment();
    now.add(1, "days");

    this.reqDeliveryDate = now.toDate();
    this.eta = now.toDate();

    this.handoverIds = window.opener.handoverIds;
    if (this.handoverIds.length) {
      // this.getData()
      this.print();
    }
  },
  methods: {
    async print() {
      const tempTitle = document.title;
      await this.getData();
      await window.print();
      document.title = tempTitle;
    },
    async getData() {
      this.$vs.loading();
      let resp = await this.$http.get(
        `/api/wms/v1/simple-outbound-processing/print-handover`,
        { params: { handover_ids: this.handoverIds, is_print: 1 } }
      );
      if (resp.code == 200) {
        let datas = resp.data;
        console.log(datas, "data disini");
        document.title = datas.reduce(
          (prev, curr) => `${prev}-${curr.shipment_number}`,
          "HANDOVER"
        );

        const maxItemsPerPage = 28;
        const paginatedData = [];
        const totalPagesMap = {};

        // First pass: Calculate total pages for each shipment_number
        datas.forEach((data) => {
          let currentItemCount = 0;
          let pageCount = 0;

          data.lines.forEach((line) => {
            line.do_line.forEach((doLine) => {
              let itemOffset = 0;

              while (itemOffset < doLine.item_lines.length) {
                const remainingSpace = maxItemsPerPage - currentItemCount;
                const itemsToTake = Math.min(remainingSpace, doLine.item_lines.length - itemOffset);

                currentItemCount += itemsToTake;
                itemOffset += itemsToTake;

                // When page limit is reached, count the page and reset item count
                if (currentItemCount === maxItemsPerPage) {
                  pageCount++;
                  currentItemCount = 0;
                }
              }
            });
          });

          // Count the last page if it has remaining items
          if (currentItemCount > 0) {
            pageCount++;
          }

          // Store total page count for this shipment_number
          totalPagesMap[data.shipment_number] = pageCount;
        });

        // Second pass: Create paginated data with shared totalPages for each shipment_number
        datas.forEach((data) => {
          let currentPageNumber = 0;
          let currentPage = {
            ...data,
            lines: [],
            isSignature: false,
            page: currentPageNumber,
            totalPages: totalPagesMap[data.shipment_number],
            totalItems: 0
          };
          let currentItemCount = 0;

          data.lines.forEach((line) => {
            let lineCopy = { ...line, do_line: [] };

            line.do_line.forEach((doLine) => {
              let itemOffset = 0;

              while (itemOffset < doLine.item_lines.length) {
                const remainingSpace = maxItemsPerPage - currentItemCount;
                const itemsToTake = Math.min(remainingSpace, doLine.item_lines.length - itemOffset);
                const itemChunk = doLine.item_lines.slice(itemOffset, itemOffset + itemsToTake);

                const doLineCopy = { ...doLine, item_lines: itemChunk };
                lineCopy.do_line.push(doLineCopy);

                currentItemCount += itemsToTake;
                itemOffset += itemsToTake;

                // When page limit is reached, create a new page
                if (currentItemCount === maxItemsPerPage) {
                  currentPage.lines.push(lineCopy);
                  currentPage.page = ++currentPageNumber;
                  currentPage.totalItems += currentItemCount
                  paginatedData.push(currentPage);

                  // Reset for the new page
                  currentPage = {
                    ...data,
                    lines: [],
                    isSignature: false,
                    page: currentPageNumber,
                    totalPages: totalPagesMap[data.shipment_number],
                    totalItems: 0,
                  };
                  lineCopy = { ...line, do_line: [] };
                  currentItemCount = 0;
                }
              }
            });

            if (lineCopy.do_line.length > 0) {
              currentPage.lines.push(lineCopy);
            }
          });

          // Add the last page if it has remaining items and set isSignature to true if it's the final page
          if (currentItemCount > 0) {
            currentPage.page = ++currentPageNumber;
            currentPage.isSignature = currentItemCount < maxItemsPerPage;
            currentPage.totalItems += currentItemCount
            paginatedData.push(currentPage);
          }
        });

        this.datas = paginatedData
        console.log(paginatedData, "paginatedData");
        console.log(this.datas);
        this.dateNow = moment().format("DD/MM/YYYY");
        this.timeNow = moment().format("HH:mm:ss");
        this.$vs.loading.close();
      } else {
        this.$vs.loading.close();
      }
    },
  },
  computed: {
    formatDate: () => {
      return (val) => {
        const momentDate = moment(val);
        // momentDate.add(1, 'days');              // Tambahkan 1 hari
        return momentDate.format("DD/MM/YYYY");
      };
    },
    formatTime: () => {
      return (val) => {
        const momentDate = moment(val).local();
        console.log(momentDate);
        return momentDate.format("hh:mm:ss");
      };
    },
  },
};
</script>
